// Styles

// Main styles

@import './app.scss';

// Hyper Icons
@import './icons.scss';

.react-select {
  &.is-invalid {
    .react-select__control {
      border-color: red;
    }
  }
  &.is-valid {
    .react-select__control {
      border-color: #0acf97;
    }
  }
}
.status-icon {
  position: absolute;
  width: 20px;
  z-index: 9;
  right: 35px;
  top: 37px;
}
